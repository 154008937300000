import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

const debounceMs = 200;

// 動物病院の検索選択機能。
export default class extends Controller {
  static targets = ["select"];

  connect() {
    // Choicesjs インスタンス。
    this.choices = null;

    // 初期データを投入して初期化する。
    this.fetchChoices().then((choices) => {
      this.choices = new Choices(this.selectTarget, {
        classNames: {
          containerOuter: "choices w-full",
        },
        shouldSort: false,
        placeholder: false,
        searchChoices: false,
        choices,
      });

      let searchTimeoutId = null;
      this.selectTarget.addEventListener("search", (event) => {
        clearTimeout(searchTimeoutId);
        searchTimeoutId = setTimeout(
          () => this.searchAndUpdate(event.detail.value),
          debounceMs,
        );
      });

      // 空文字だと search イベントが発火しないので、隠された時に初期化しておく。
      this.selectTarget.addEventListener("hideDropdown", (_) => {
        this.searchAndUpdate("");
      });
    });
  }

  searchAndUpdate(query) {
    this.fetchChoices(query).then((choices) => {
      this.choices.setChoices(choices, "value", "label", true);
    });
  }

  async fetchChoices(query = "") {
    // queryが無ければ全件取得したい。
    // TODO(ueda): ドロップダウンに記載するスペースが無さそうだったので住所は検索対象外にしている。 See https://github.com/anipos/cameleon/pull/3444#discussion_r1000809577.
    const queryParam = new URLSearchParams({
      "veterinary_clinic_search[query]": query,
    });

    return fetch(`/veterinary_clinics.json?${queryParam}`)
      .then((res) => res.json())
      .then((data) =>
        data.veterinary_clinics.map((e) => ({
          value: e.id,
          label: `${e.name} (${e.phone_number})`,
        })),
      );
  }
}
